<template>
    <img
        class="w-auto"
        :src="isDark ? '/farbcode-logo.svg' : '/farbcode-logo-dark.svg'"
        alt="Logo">
</template>

<script setup>
let isDark = null

if (useFeature('darkMode')) {
    isDark = useDark({
        disableTransition: false
    })
}
</script>